<template>
  <div>
    <h2 v-if="data?.header?.title" class="text-center text-2xl text-green-900 md:text-5xl">
      {{ data.header.title }}
    </h2>
    <div v-if="!isEmpty" class="items-between w-full flex-row justify-center gap-8 md:flex md:gap-20">
      <SharedTestimonialNumberOfCard
        v-for="(item, index) in data?.numberOfSection?.numberOfItems"
        :key="index"
        :data="item" />
    </div>
    <div v-if="data?.testimonials.data.length > 0" data-nosnippet="true">
      <SharedCarouselSection :data="chunkedTestimonials.mobile" class="lg:hidden" />
      <SharedCarouselSection :data="chunkedTestimonials.desktop" class="hidden lg:block" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TestimonialComponent } from "~/types/strapi/components/block/interfaces/TestimonialComponent";

const props = defineProps<{
  data: TestimonialComponent;
}>();

export type TestimonialRenderParts = {
  content: string;
  reviewer: string;
  rating?: string;
};

export type TestimonialPerPage = {
  [key: number]: TestimonialRenderParts[] | undefined;
};

const chunkedTestimonials = computed(() => {
  const data = props.data?.testimonials.data ?? [];
  const desktopItemsPerPart = 3;
  const parts = Math.ceil(data.length / desktopItemsPerPart);

  return data.reduce<{ desktop: TestimonialPerPage; mobile: TestimonialPerPage }>(
    (accumulator, testimonial, index) => {
      const page = index % parts;

      if (accumulator.desktop[page] === undefined) {
        accumulator.desktop[page] = [];
      }
      if (accumulator.mobile[index] === undefined) {
        accumulator.mobile[index] = [];
      }

      const testimonialRenderPart: TestimonialRenderParts = {
        content: testimonial.attributes.content,
        rating: testimonial.attributes.rating,
        reviewer: testimonial.attributes.reviewer,
      };

      accumulator.desktop[page]?.push(testimonialRenderPart);
      accumulator.mobile[index]?.push(testimonialRenderPart);

      return accumulator;
    },
    { desktop: [], mobile: [] },
  );
});

const isEmpty = computed(() => props.data?.numberOfSection === undefined);
</script>

<style lang="scss" scoped></style>
